// Import dependencies
import React from 'react'

// Import components
import TextSection from '../general/textSection'

// Import styles
import './yearsection.css'

const YearSection = ({year, title, paragraph}) => (
    <div className='yearsection__container'>
        <div className='yearsection__container-inner'>
            <h1 className='yearsection__year-digit'>
                {year}
            </h1>
            <div className='yearsection__content-container'>
                <TextSection
                    title={title}
                    paragraph={paragraph}
                />
            </div>
        </div>
    </div>
)

export default YearSection
