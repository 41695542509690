// Import dependencies
import React from 'react'

// Import components
import { ParagraphText } from '../styled/UILibrary'

// Import styles
import './historynavigator.css'

const HistoryNavigator = ({ visible, currentYear }) => (
    <div className={
        visible ?
            'historynavigator__container historynavigator__visible'
            :
            'historynavigator__container'
    }>
        <div className='historynavigator__container-inner'>
            <ParagraphText minSize={12} maxSize={18} bold={currentYear === '1930'} nolineheight>
                1930
            </ParagraphText>
            <div className={
                currentYear === '1930' ?
                    'historynavigator__vertical-line historynavigator__vertical-line-highlight'
                    :
                    'historynavigator__vertical-line'
            } />
            <ParagraphText minSize={12} maxSize={18} bold={currentYear === '1950'} nolineheight>
                1950
            </ParagraphText>
            <div className={
                currentYear === '1950' ?
                    'historynavigator__vertical-line historynavigator__vertical-line-highlight'
                    :
                    'historynavigator__vertical-line'
            } />
            <ParagraphText minSize={12} maxSize={18} bold={currentYear === '1973'} nolineheight>
                1973
            </ParagraphText>
            <div className={
                currentYear === '1973' ?
                    'historynavigator__vertical-line historynavigator__vertical-line-highlight'
                    :
                    'historynavigator__vertical-line'
            } />
            <ParagraphText minSize={12} maxSize={18} bold={currentYear === '1996'} nolineheight>
                1996
            </ParagraphText>
        </div>
    </div>
)

export default HistoryNavigator
