// Import dependencies
import React from "react"
import { Waypoint } from "react-waypoint"

// Import components
import Layout from "../components/layouts/layout"
import SEO from "../components/seo/seo"
import Header from "../components/header/header"
import HomeHeader from "../components/home/homeHeader"
import TextSection from "../components/general/textSection"
import ContactCallToAction from "../components/general/contactAction"
import YearSection from "../components/history/yearSection"
import HistoryNavigator from "../components/history/historyNavigator"
import { PageContainer, Margins, Gap150 } from "../components/styled/UILibrary"

// Import images
import HeaderImage from "../components/images/historyHeader"

// Import styles
import "./styles/history.css"
import "./styles/scrollbar.css"

export default class HistoryPage extends React.Component {
  constructor(...args) {
    super(...args)
    this.state = {
      navigatorVisible: false,
      currentYearVisible: "",
    }
    this.showNavigator = this.showNavigator.bind(this)
    this.hideNavigator = this.hideNavigator.bind(this)
    this.highlightYear = this.highlightYear.bind(this)
  }

  showNavigator() {
    this.setState({ navigatorVisible: true })
  }
  hideNavigator() {
    this.setState({ navigatorVisible: false })
  }

  highlightYear(year) {
    this.setState({ currentYearVisible: year })
  }

  componentDidMount() {
    // Hello for developers
    console.clear()
    console.log(
      "%cDesigned & developed by Dmitry Poliyivets – https://www.linkedin.com/in/dpoliyivets/",
      "background: #0000FF; color: #ffffff; font-size: 12px; padding: 25px; font-family: 'Montserrat', sans-serif; line-height: 22px"
    )
  }

  render() {
    return (
      <Layout>
        <SEO title="History" keywords={[`gatsby`, `application`, `react`]} />

        <div className="history__container">
          <Header />
          <HeaderImage />
          <HomeHeader title="90 years of history" />

          {/* Page contents */}
          <PageContainer margin={"0"}>
            <div className="history__navigation-container">
              <HistoryNavigator
                visible={this.state.navigatorVisible}
                currentYear={this.state.currentYearVisible}
              />
            </div>

            <Margins>
              <Gap150 />

              <TextSection
                smallTitle="OUR STORY"
                title="The beginning of a family adventure"
                paragraph="What began by boiling the collagen out of animal bones to make glue became the company that helped bring Egypt’s acrylic industry to life. Today, we are one of Egypt’s most respected companies, known for our integrity and dedication to serve our customer’s niche needs."
                name="our-story"
              />

              {/* Year full screen sections */}
              <Waypoint
                onEnter={this.showNavigator}
                onLeave={this.hideNavigator}
              >
                <div>
                  <Waypoint onEnter={() => this.highlightYear("1930")}>
                    <div name="1930">
                      <YearSection
                        year={1930}
                        title="Grandfather"
                        paragraph="Spiroplastic was born in 1973 to pioneer the production of cast acrylic sheets in Egypt. But that’s not our true origin story. The company’s history begins in the 1930s when our founder, Spiros Moros, opened Lion Glue, a manufacturer that made adhesives for the British Army. They began by using pots to boil the collagen out of animal bones to make glue."
                      />
                    </div>
                  </Waypoint>

                  <Waypoint onEnter={() => this.highlightYear("1950")}>
                    <div name="1950">
                      <YearSection
                        year={1950}
                        title="Father"
                        paragraph="During the 1950s, Spiro Moros’s son, Costas Moros began producing PVC and was a trailblazer of the recycling industry in Egypt too. Then something truly amazing happened."
                      />
                    </div>
                  </Waypoint>

                  <Waypoint onEnter={() => this.highlightYear("1973")}>
                    <div name="1973">
                      <YearSection
                        year={1973}
                        title="Son"
                        paragraph="Costas developed the formula for the first acrylic sheets in a small kitchen. He was central to the founding of the Plexiglass industry in Egypt. After some more experimentation, Costa’s Son, Spiros standardized the formula for production and began producing large quantities of acrylic sheets in 1973."
                      />
                    </div>
                  </Waypoint>

                  <Waypoint onEnter={() => this.highlightYear("1996")}>
                    <div name="1996">
                      <YearSection
                        year={1996}
                        title="Public limited company"
                        paragraph="In 1996 Spiroplastic became a joint-stock company, known in Egypt as a “Société Anonyme” (SA). An SA is like a “public limited company” in places like the United Kingdom and United States. We operate subject to the rules and regulations of our country and its publicly traded markets."
                      />
                    </div>
                  </Waypoint>
                </div>
              </Waypoint>

              <Gap150 name="history-needs-title" />

              <TextSection
                smallTitle="PRESENT TIME"
                title="Staying ahead of the curve"
                paragraph={
                  "We remain competitive because we have the experience that enables us to stay ahead of the market on quality while keeping our pricing competitive. We provide a wide range of products, colors, dimensions, and thicknesses that we can fulfill the most comprehensive requests."
                }
              />

              <Gap150 />

              <ContactCallToAction />
            </Margins>
          </PageContainer>
        </div>
      </Layout>
    )
  }
}
